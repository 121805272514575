'use client';
import { useEffect, useState } from 'react';

import { Locale } from '../types/localization';

export const useLocaleFromLocation = () => {
  const [locale, setLocale] = useState(Locale.GERMAN_GERMANY);

  useEffect(() => {
    const locationHref = window.location.href;

    if (locationHref.includes(`/${Locale.GERMAN_GERMANY}`)) {
      setLocale(Locale.GERMAN_GERMANY);

      return;
    }

    if (locationHref.includes(`/${Locale.ENGLISH_USA}`)) {
      setLocale(Locale.ENGLISH_USA);

      return;
    }

    setLocale(Locale.FAKE_DEFAULT);
  }, []);

  return locale;
};
