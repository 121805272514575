import { useRouter } from 'next/compat/router';
import { GetStaticPropsContext, NextPageContext } from 'next/types';
import { useCallback } from 'react';

import {
  CountryCode,
  DefaultLocale,
  FakeDefaultLocale,
  Locale,
} from '../types/localization';
import { setLocaleCookie } from './cookies';
import { useLocaleFromLocation } from './useLocaleFromLocation';

export function loadCurrentLocale(locale: string) {
  const messages = require(`~/localization/locales/${locale}.json`);
  const region = locale.includes(CountryCode.US)
    ? CountryCode.US
    : CountryCode.DE;

  return {
    currency: region === CountryCode.US ? '$' : '€',
    messages,
    locale,
    region,
    isUSA: region === CountryCode.US,
  };
}

export const getLocaleMetaData = (locale: string) => {
  // refactor function to support more regions
  const region = locale?.includes(CountryCode.US)
    ? CountryCode.US
    : CountryCode.DE;
  const localePrefix = locale !== FakeDefaultLocale ? `/${locale}` : '';

  return {
    currency: region === CountryCode.US ? '$' : '€',
    locale: locale === FakeDefaultLocale ? DefaultLocale : locale,
    region,
    isUSA: region === CountryCode.US,
    isDE: region === CountryCode.DE,
    localePrefix,
  };
};

export function useLocaleChanger() {
  const router = useRouter();

  return useCallback(
    (locale: string) => {
      setLocaleCookie(locale as Locale);
      router?.push('/');
    },
    [router]
  );
}

export const getLocaleFromContext = (
  ctx: NextPageContext | GetStaticPropsContext
) =>
  ctx.locale === FakeDefaultLocale
    ? DefaultLocale
    : ctx.locale || DefaultLocale;

export const useCurrentLocale = () => {
  const localeFromLocation = useLocaleFromLocation();

  return getLocaleMetaData(localeFromLocation);
};

/**
 * Enhances a given URL with the specified locale if it's a relative URL and not already localized.
 * @param url The URL to be enhanced with the locale.
 * @param locale The locale to add to the URL.
 * @returns The URL prefixed with the locale if it was a relative URL and not already localized.
 */

function removeTrailingSlash(url: string): string {
  return url.endsWith('/') ? url.slice(0, -1) : url;
}

export function localizeUrl(url: string, locale: string) {
  const hasHttpProtocol = url.startsWith('http');
  const samePageAnchorLink = url.startsWith('#');
  const supportedLocales = [
    Locale.ENGLISH_USA,
    Locale.ENGLISH_GERMANY,
    Locale.GERMAN_GERMANY,
  ];
  const containsSupportedLocale = supportedLocales.some((sl) =>
    url.includes(`/${sl}`)
  );

  if (hasHttpProtocol || containsSupportedLocale || samePageAnchorLink) {
    return url;
  }

  const normalizedUrl = url.startsWith('/') ? url : `/${url}`;

  return `/${locale}${removeTrailingSlash(normalizedUrl)}`;
}
