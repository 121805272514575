'use client';
import { useEffect, useState } from 'react';

import tailwindConfig from '../../tailwind.config';

const sizesMap = tailwindConfig?.theme?.screens as Record<
  'xs' | 'mobile' | 'sm' | 'md' | 'lg' | 'xl' | 'max',
  string
>;

export const useHasHover = () => {
  try {
    return matchMedia('(hover: hover)').matches;
  } catch {
    // Assume that if browser too old to support matchMedia it's likely not a touch device
    return true;
  }
};

// https://github.com/shadcn-ui/ui/blob/main/apps/www/hooks/use-media-query.tsx
export const useMediaQuery = (size: keyof typeof sizesMap) => {
  const [value, setValue] = useState(false);

  useEffect(() => {
    try {
      const onChange = (event: MediaQueryListEvent) => setValue(event.matches);
      const result = matchMedia(`(max-width: ${sizesMap[size]})`);
      result?.addEventListener?.('change', onChange);
      setValue(result.matches);

      return () => result?.removeEventListener?.('change', onChange);
    } catch {
      setValue(false);

      return () => {
        // empty
      };
    }
  }, [size]);

  return value;
};
