import { cn } from '@finn/ui-utils';
import {
  Content,
  Description,
  Overlay,
  Portal,
  Root,
  Title,
  Trigger,
} from '@radix-ui/react-dialog';
import {
  Children,
  cloneElement,
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  HTMLAttributes,
  ReactElement,
  ReactNode,
} from 'react';

import { getComponentDisplayName } from '../../internal/react';
import { renderDefaultHeader } from './common';

const DialogOverlay = forwardRef<
  ElementRef<typeof Overlay>,
  ComponentPropsWithoutRef<typeof Overlay>
>(({ className, ...props }, ref) => (
  <Overlay
    ref={ref}
    className={cn(
      // position
      'z-modal fixed inset-0 bg-black/45',
      // overlay animations
      'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      className
    )}
    {...props}
  />
));

DialogOverlay.displayName = Overlay.displayName;

const DialogContent = forwardRef<
  ElementRef<typeof Content>,
  ComponentPropsWithoutRef<typeof Content> & {
    onBack?: () => void;
    variant?: 'large' | 'small';
    renderHeader?: ({ onBack }: { onBack?: () => void }) => ReactNode;
  }
>(
  (
    {
      className,
      children,
      variant,
      onBack,
      renderHeader = renderDefaultHeader,
      ...props
    },
    ref
  ) => (
    <Portal>
      <DialogOverlay />
      <Content
        ref={ref}
        className={cn(
          // position
          'z-modal fixed left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]',
          // layout
          'box-border flex flex-col gap-8 overflow-auto bg-white',
          // sizes
          'h-full max-h-full w-full p-4 md:h-max md:w-[832px] md:rounded md:p-14',
          // animations
          'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] duration-200',
          {
            'md:w-[470px]': variant === 'small',
          },
          className
        )}
        {...props}
      >
        {renderHeader({ onBack })}
        {Children.map(
          children as ReactElement<{ className?: string; variant?: string }>,
          (child) => {
            if (getComponentDisplayName(child) === 'ModalFooter') {
              return cloneElement(child, {
                variant,
              });
            }

            return child;
          }
        )}
      </Content>
    </Portal>
  )
);
DialogContent.displayName = Content.displayName;

const DialogHeader = ({
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => (
  <div className={cn('flex flex-col space-y-4', className)} {...props} />
);
DialogHeader.displayName = 'DialogHeader';

const DialogFooter = ({
  className,
  children,
  variant,
  ...props
}: HTMLAttributes<HTMLDivElement> & { variant?: 'large' | 'small' }) => (
  <div
    className={cn(
      'flex h-full w-full flex-col items-center justify-end gap-2 md:h-fit md:p-4',
      {
        'md:p-0': variant === 'small',
      },
      className
    )}
    {...props}
  >
    {Children.map(children as ReactElement<{ className?: string }>, (child) =>
      cloneElement(child, {
        className: cn(child?.props?.className, 'w-full md:w-max md:min-w-44', {
          'md:w-full': variant === 'small',
        }),
      })
    )}
  </div>
);
DialogFooter.displayName = 'DialogFooter';

const DialogTitle = forwardRef<
  ElementRef<typeof Title>,
  ComponentPropsWithoutRef<typeof Title>
>(({ className, ...props }, ref) => (
  <Title
    ref={ref}
    className={cn('mobile-t3-semibold md:web-t4-semibold', className)}
    {...props}
  />
));

DialogTitle.displayName = Title.displayName;

const DialogSubtitle = forwardRef<
  ElementRef<typeof Description>,
  ComponentPropsWithoutRef<typeof Description>
>(({ className, ...props }, ref) => (
  <Description
    ref={ref}
    className={cn('body-16-light', className)}
    {...props}
  />
));
DialogSubtitle.displayName = Description.displayName;

export {
  Root as DialogRoot,
  Trigger as DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogSubtitle,
};
