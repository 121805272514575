import { ClosedUserGroupProvider } from '@finn/auto-ui/contexts/ClosedUserGroup';
import { useSetTestCookieForDev } from '@finn/auto-ui/hooks/useSetTestCookieForDev';
import { MobileAppBindings } from '@finn/ua-app/layouts/MobileAppRootLayout';
import { run } from '@finn/ua-app/sdk';
import { GoogleOneTap } from '@finn/ua-auth';
import { AllFeatures, FeatureProvider } from '@finn/ua-featureflags';
import { DateInfoProvider } from '@finn/ui-components';
import { AppProvider } from '@finn/ui-modules';
import {
  config,
  CookieKeys,
  DefaultLocale,
  FakeDefaultLocale,
  formatUtmSourceValue,
  getClientCookie,
  getDefaultRichTextElements,
  isCookieExist,
  isMobileApp,
  isServer,
  loadCurrentLocale,
  Locale,
  pageEvent,
  renderSegmentSnippet,
  setClientCookie,
  setCurrentLang,
  setCustomTags,
  setLocaleCookie,
  setMessages,
  useLocaleChanger,
} from '@finn/ui-utils';
import trimEnd from 'lodash/trimEnd';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import queryString from 'query-string';
import React, { ReactNode, useEffect, useMemo } from 'react';

import { GlobalModals } from '~/components/GlobalModals/GlobalModals';
import { useProcessVoucherParam } from '~/hooks/useProcessVoucherParam';
import { useReferralTracker } from '~/hooks/useReferralTracker';
import { useSegmentLoader } from '~/hooks/useSegmentLoader';
import { useSetSessionId } from '~/modules/api/hooks/useSetSessionId';

import { FullStory } from '../FullStory/FullStory';

const defaultRichTextElements = getDefaultRichTextElements();

export type FinnAppProps = AppProps & {
  err: Error;
  children?: ReactNode;
};

const EMPTY_FEATURES = {
  'de-DE': {},
  'en-US': {},
};

export default function <TProps extends FinnAppProps = FinnAppProps>(
  useMiddleware: () => void,
  Providers: React.FC<TProps>,
  allFeatures: AllFeatures = EMPTY_FEATURES
) {
  return function MyApp(props: TProps) {
    const { pageProps, Component, err } = props;
    const router = useRouter();
    const localeToLoad =
      router.locale === FakeDefaultLocale
        ? DefaultLocale
        : router.locale || DefaultLocale;
    const { locale, messages } = useMemo(
      () => loadCurrentLocale(localeToLoad),
      [localeToLoad]
    );
    const onChangeLocale = useLocaleChanger();

    /*
      Very dirty hack to try to overcome netlify bug with locales
      TODO delete me please https://github.com/netlify/netlify-plugin-nextjs/issues/788
    */
    useEffect(() => {
      const localeFromUrl = window.location.pathname?.split('/')?.[1];
      const { pathname, locales } = router;
      const isSupportedLocale = locales?.includes(localeFromUrl);
      if (pathname !== '/' || isSupportedLocale) {
        if (isSupportedLocale) {
          setLocaleCookie(localeFromUrl as Locale);
        } else {
          setLocaleCookie(DefaultLocale);
        }
      }

      const localeInCookie = getClientCookie(CookieKeys.NEXT_LOCALE);
      if (
        localeInCookie === Locale.ENGLISH_USA &&
        localeFromUrl !== Locale.ENGLISH_USA
      ) {
        onChangeLocale(localeInCookie);
      }
    }, [locale, onChangeLocale]);

    useEffect(() => {
      // Remove the server-side injected CSS.
      const jssStyles = document.querySelector('#jss-server-side');
      if (jssStyles && jssStyles.parentElement) {
        jssStyles.parentElement.removeChild(jssStyles);
      }
    }, []);

    // Read b2b-multiplier from url and store in cookie
    useEffect(() => {
      const parsedURLQuery = queryString.parse(window.location.search);
      const b2bMultiplierValue = parsedURLQuery['b2b-multiplier'];

      if (b2bMultiplierValue) {
        setClientCookie(CookieKeys.B2B_MULTIPLIER, b2bMultiplierValue, 30);
      }
    }, []);

    // Read utm_source from url and store in cookie
    // Marketing cookies are saved based on first origin that customer came from
    // and they don't overridden when customer comes after from a different place/marketing campaign.
    useEffect(() => {
      const parsedURLQuery = queryString.parse(window.location.search);
      let utmSourceValue = parsedURLQuery.utm_source;
      if (Array.isArray(utmSourceValue)) {
        utmSourceValue = utmSourceValue[0];
      }

      const isUTMCookieExist = isCookieExist(CookieKeys.UTM_SOURCE);

      if (utmSourceValue && !isUTMCookieExist) {
        const formattedSourceValue = formatUtmSourceValue(utmSourceValue);
        setClientCookie(CookieKeys.UTM_SOURCE, formattedSourceValue, 30);
      }
    }, []);

    useMiddleware();
    useSetSessionId();

    // Track (Page Load/History change) with Segment
    useEffect(() => {
      if (router.isReady) {
        let path = router.asPath;
        path = path.split('?')[0];
        if (router.locale !== FakeDefaultLocale) {
          path = trimEnd(`/${router.locale}${router.asPath}`, '/');
        }
        pageEvent(path);
      }
    }, [router.asPath, router.isReady, router.locale]);

    useSegmentLoader();
    useReferralTracker();
    useSetTestCookieForDev();
    useProcessVoucherParam();

    const withNewAppBindings =
      (!router?.asPath?.includes('/mobile') ||
        router?.asPath?.includes('/authv2') ||
        router?.asPath?.includes('/register')) &&
      isMobileApp();

    setCurrentLang(locale);
    setMessages(messages);
    setCustomTags({
      ...defaultRichTextElements,
    });

    return (
      <>
        {!isServer() && !window?.analytics && (
          <Script
            dangerouslySetInnerHTML={{
              __html: renderSegmentSnippet(),
            }}
          />
        )}

        {/*
            We supress cookie banner for mobile app
            https://docs.usercentrics.com/#/cmp-v2-ui-api?id=suppress-the-cmp
          */}
        {pageProps.suppressCookieBanner || isMobileApp() ? (
          <Script>{`var UC_UI_SUPPRESS_CMP_DISPLAY=true;`}</Script>
        ) : null}
        {pageProps.isConnectUsercentric && (
          <>
            <Script
              id="usercentrics-cmp"
              src="https://app.usercentrics.eu/browser-ui/latest/loader.js"
              data-settings-id={config.USERCENTRICS_ID}
            />
          </>
        )}

        <Head>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
          />
          <meta charSet="UTF-8" />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/assets/favicon/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/assets/favicon/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/assets/favicon/favicon-16x16.png"
          />
          <link rel="manifest" href="/site.webmanifest" />
          <link
            rel="mask-icon"
            href="/assets/favicon/safari-pinned-tab.svg"
            color="#5bbad5"
          />
          <meta name="msapplication-TileColor" content="#da532c" />
          <meta name="theme-color" content="#ffffff" />

          <link
            rel="preload"
            href="/fonts/SuisseIntl/SuisseIntl-SemiBold.woff2"
            as="font"
            type="font/woff2"
            crossOrigin={''}
          />

          <link
            rel="preload"
            href="/fonts/Inter/Inter-Light.woff2"
            as="font"
            type="font/woff2"
            crossOrigin={''}
          />

          <link
            rel="preload"
            href="/fonts/Inter/Inter-Medium.woff2"
            as="font"
            type="font/woff2"
            crossOrigin={''}
          />

          <link
            rel="preload"
            href="/fonts/Inter/Inter-Regular.woff2"
            as="font"
            type="font/woff2"
            crossOrigin={''}
          />

          <link
            rel="preload"
            href="/fonts/Inter/Inter-SemiBold.woff2"
            as="font"
            type="font/woff2"
            crossOrigin={''}
          />

          <link rel="preconnect" href="https://res.cloudinary.com" />
          <link rel="dns-prefetch" href="https://res.cloudinary.com" />
          <link rel="preconnect" href={config.PRODUCT_API_URL} />
          <link rel="dns-prefetch" href={config.PRODUCT_API_URL} />
        </Head>
        <FeatureProvider allFeatures={allFeatures}>
          <FullStory />
          <AppProvider>
            <DateInfoProvider>
              <ClosedUserGroupProvider>
                <Providers {...props}>
                  <GoogleOneTap />
                  <Component {...pageProps} err={err} />
                  <GlobalModals />
                  {withNewAppBindings && <MobileAppBindings />}
                </Providers>
              </ClosedUserGroupProvider>
            </DateInfoProvider>
          </AppProvider>
        </FeatureProvider>
      </>
    );
  };
}

run();
