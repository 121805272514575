import { type UserAccountSignIn } from '@finn/ui-utils';
import {
  clearClientCookie,
  CookieKeys,
  isServer,
  Locale,
  traceabilityHeadersBrowser,
} from '@finn/ui-utils/ssr';
import axios from 'axios';
import { signIn, signOut as nextAuthSignOut } from 'next-auth/react';

const BASE_URL = `${
  !isServer?.() ? window.origin : process.env.NEXTAUTH_URL || ''
}/api/auth`;
const SERVER_ERROR = 'server_error';

const callSignIn = async (
  provider: string,
  options: { [key: string]: string | undefined }
): Promise<UserAccountSignIn> => {
  try {
    const data = (await signIn(provider, {
      redirect: false,
      ...(typeof window?.nativeAppSDK !== 'undefined'
        ? { appVersion: window?.nativeAppSDK?.getTrackingProps().app_version }
        : {}),
      ...options,
    })) as unknown as UserAccountSignIn;
    data.ok = data.ok && !data.error;

    if (data.url?.includes('csrf=true')) {
      if ('password' in options) {
        delete options.password;
      }
      throw new Error(
        `Invalid CSRF token for callbacks. Callback Provider: ${provider} Options: ${JSON.stringify(
          options
        )}`
      );
    }

    return data;
  } catch (error) {
    return {
      status: 500,
      ok: false,
      error: SERVER_ERROR,
      url: null,
    };
  }
};

const callCustomEndpoint = async (
  endpoint: string,
  payload: { [key: string]: string }
): Promise<UserAccountSignIn> => {
  let status = 500;
  let data = null;
  try {
    const result = await axios.post(`${BASE_URL}/custom/${endpoint}`, payload, {
      headers: traceabilityHeadersBrowser(),
    });
    status = result.status;
    data = result.data;
  } catch (err) {
    const error = err as Error & { response: { status: number; data: any } };

    return {
      status,
      ok: false,
      error:
        (error.response.status === 404 && error.response?.data?.message) ||
        SERVER_ERROR,
      url: null,
    };
  }

  return {
    status,
    ok: status === 200 && data.success,
    error: data.message,
    url: null,
  };
};

type ExtendedFields = {
  firstname?: string;
  lastname?: string;
  phone?: string;
  birthday?: string;
};

export const register = async (
  email: string,
  password: string,
  locale: Locale,
  token: string,
  e2eSecret: string = '',
  extendedFields?: ExtendedFields
): Promise<UserAccountSignIn> => {
  const { firstname, lastname, phone, birthday } = extendedFields || {};

  return await callSignIn('register', {
    email,
    password,
    firstname,
    lastname,
    phone,
    birthday,
    locale,
    token,
    e2eSecret,
  });
};

export const getCsrfToken = async (): Promise<string> => {
  const result = await axios.get(`${BASE_URL}/csrf`);

  return result.data.csrfToken;
};

export const login = async (
  email: string,
  password: string
): Promise<UserAccountSignIn> => {
  return await callSignIn('login', { email, password });
};

export const loginWithCode = async (
  code: string
): Promise<UserAccountSignIn> => {
  return await callSignIn('loginWithCode', { code });
};

export const loginWithMagicLink = async (
  token: string
): Promise<UserAccountSignIn> => {
  return await callSignIn('loginWithMagicLink', { token });
};

export const signOut = async (callbackUrl?: string) => {
  if (!isServer()) {
    clearClientCookie(CookieKeys.SESSION_DATA);
    clearClientCookie(CookieKeys.LAST_URL);
  }

  await callCustomEndpoint('refreshCode', {});

  return await nextAuthSignOut({ redirect: false, callbackUrl });
};

type AuthTokenReponse = {
  authToken: string;
};

export const fetchAuthToken = async (): Promise<AuthTokenReponse> => {
  // added for debugging, remove after it's done
  const res = await axios.get(`${BASE_URL}/custom/authToken`);

  const { data } = res;

  return data;
};

export const fetchSession = async (): Promise<{ user?: object }> => {
  const res = await axios.get(`${BASE_URL}/session`);

  const { data } = res;

  return data;
};

export const loginWithToken = async (
  token: string
): Promise<UserAccountSignIn> => {
  return await callSignIn('loginWithToken', { token });
};

export const forgotPassword = async (
  email: string,
  locale: Locale
): Promise<UserAccountSignIn> => {
  const payload = {
    email,
    locale,
  };

  return await callCustomEndpoint('forgotPassword', payload);
};

export const changePassword = async (
  old_password: string,
  password: string,
  locale: Locale
): Promise<UserAccountSignIn> => {
  const csrf_token = await getCsrfToken();
  const payload = {
    old_password,
    password,
    locale,
    csrf_token,
  };

  return await callCustomEndpoint('changePassword', payload);
};

export const changePasswordWithCode = async (
  code: string,
  new_password: string,
  locale: Locale
): Promise<UserAccountSignIn> => {
  const csrf_token = await getCsrfToken();

  const payload = {
    code,
    new_password,
    locale,
    csrf_token,
  };

  return await callCustomEndpoint('changePasswordWithCode', payload);
};

export const resendVerification = async (
  email: string,
  locale: Locale,
  nextUrl: string = ''
): Promise<UserAccountSignIn> => {
  const payload = {
    email,
    locale,
    nextUrl,
  };

  return await callCustomEndpoint('resendVerification', payload);
};

export const verifyCode = async (code: string): Promise<UserAccountSignIn> => {
  const payload = {
    code,
  };

  return await callCustomEndpoint('verifyCode', payload);
};

export const refreshCode = async (): Promise<UserAccountSignIn> => {
  return await callCustomEndpoint('refreshCode', {});
};
